"use client";

import router from "next/router";
import { ReactNode } from "react";

import { env } from "@saas/config/shared";
import { ampli, eventGA } from "@saas/core";
import { RightIcon } from "@saas/shared/icon";
import { BaseButton, Divider, Typography } from "@saas/shared/ui";
import { classNames, useHasMounted } from "@saas/shared/utils";

import { useSessionProfile } from "..";

export interface AccountActionsProps {
  children: ReactNode;
  accountMenu: () => JSX.Element;
  showActionButtons?: boolean;
}

export const AccountActions = ({
  accountMenu: AccountMenu,
  children,
  showActionButtons = true,
}: AccountActionsProps) => {
  const hasMounted = useHasMounted();
  const { profile } = useSessionProfile();

  const redirectToRegister = () => {
    const { location } = window;

    router.push(env.DASHBOARD_URL + "/register");
    eventGA({
      name: "Tap Register",
      params: {
        category: "Landing Page",
        action: "click",
        position: "header",
      },
    });

    ampli.clickRegister({
      action: "click_register_button",
      section: "Header",
      button_name: "Daftar sekarang",
      page_domain: location.origin,
      page_location: location.href,
      page_path: location.pathname,
      page_title: "Melaka",
      page_url: location.href,
    });
  };

  const AuthenticatedContent = () => {
    return (
      <div
        className={classNames(
          "items-center gap-6 md:gap-2",
          showActionButtons ? "flex" : "hidden"
        )}
      >
        <button
          className={
            "text-disabled enabled:text-he border-neutral-N300 enabled:hover:text-le enabled:focus:text-button group inline-flex hidden items-center justify-center gap-2 rounded-xl border py-3 px-6 enabled:focus:outline-none lg:flex"
          }
          onClick={() => router.push(env.DASHBOARD_URL)}
        >
          <Typography type={"button-large"}>Kembali Ke Dashboard</Typography>
          <RightIcon
            className={"text-disabled group-enabled:text-le ml-2 h-4 w-4"}
          />
        </button>
        <Divider
          className={"flex min-h-[24px] md:hidden md:min-h-[42px]"}
          orientation={"vertical"}
        />
        <AccountMenu />
      </div>
    );
  };

  const UnauthenticatedContent = () => {
    return (
      <div
        className={classNames(
          "items-center ",
          showActionButtons ? "hidden lg:flex" : "hidden"
        )}
      >
        <BaseButton
          variant={"invert"}
          size={"big"}
          className={"mr-6 uppercase"}
          testid={"landing__button__login"}
          onClick={() => router.push(env.DASHBOARD_URL + "/login")}
        >
          Masuk
        </BaseButton>
        <BaseButton
          variant={"primary"}
          size={"big"}
          className={"uppercase"}
          testid={"landing-header__button__sign-up"}
          onClick={redirectToRegister}
        >
          Daftar Sekarang
        </BaseButton>
      </div>
    );
  };

  if (!hasMounted) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex items-center",
        showActionButtons && profile?.isActive ? "md:gap-6" : "lg:gap-6"
      )}
    >
      {children}

      {showActionButtons ? (
        <Divider
          orientation={"vertical"}
          className={classNames(
            "hidden min-h-[24px] md:min-h-[42px]",
            profile?.isActive ? "md:flex" : "lg:flex"
          )}
        />
      ) : null}

      {profile?.isActive ? (
        <AuthenticatedContent />
      ) : (
        <UnauthenticatedContent />
      )}
    </div>
  );
};

export default AccountActions;
